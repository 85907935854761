var systemJsConfig = {
    baseURL: '/assets/js',
    map: {
        lib: 'common/lib',

        hy: 'core/hy',
        temp: 'common/temp',

        events: 'core/events/event-list.js',

        t3: 'core/vendor/t3js/t3.js',
        jquery: 'common/lib/jquery/jquery.js',
        lodash: 'common/lib/lodash/lodash.js',

        conditioner: 'core/vendor/conditioner/conditioner.js',
        'utils/Observer': 'core/vendor/conditioner/utils/Observer.js',
        'utils/mergeObjects': 'core/vendor/conditioner/utils/mergeObjects.js',

        'core/app': 'core/app.js'
    },
    meta: {
        'core/vendor/conditioner/conditioner': [
            // DOMContentLoaded is required for addEventListener to shim the 'DOMContentLoaded' event
            'core/vendor/conditioner/shim/DOMContentLoaded',
            'core/vendor/conditioner/shim/addEventListener',

            // Other small shims
            'core/vendor/conditioner/shim/Array.forEach',
            'core/vendor/conditioner/shim/Array.filter',
            'core/vendor/conditioner/shim/Array.map',
            'core/vendor/conditioner/shim/Array.indexOf',
            'core/vendor/conditioner/shim/Array.isArray',
            'core/vendor/conditioner/shim/Function.bind',
            'core/vendor/conditioner/shim/Object.create',
            'core/vendor/conditioner/shim/Object.keys,js'

        ],
        'core/vendor/conditioner/monitors/media.js': {
            deps: [
                'core/vendor/conditioner/shim/matchMedia',
                'core/vendor/conditioner/shim/matchMedia.addListener'
            ]
        }
    },
    packages: {
        '': {
            defaultExtension: 'js'
        }
    }
};

if(typeof module !== 'undefined') {
    module.exports = systemJsConfig;
} else {
    window.define = System.amdDefine;
    window.require = window.requirejs = System.amdRequire;
}

System.config(systemJsConfig);
System.import('lib/picturefill/picturefill.js');
System.import('core/app').then(function(app) {
    app.start(window, 'HY', [
        { key: 'eq-small',           value: '(max-width: 767px)' },
        { key: 'eq-medium',          value: '(min-width: 768px) and (max-width: 1023px)' },
        { key: 'eq-large',           value: '(min-width: 1024px) and (max-width: 1299px)' },
        { key: 'gt-small',           value: '(min-width: 768px)' },
        { key: 'gt-medium',          value: '(min-width: 1024px)' },
        { key: 'gt-large',           value: '(min-width: 1300px)' },
        { key: 'lte-medium',         value: '(max-width: 1023px)' },
        { key: 'lte-large',          value: '(max-width: 1299px)' },
        { key: 'gt-small-lte-large', value: '(min-width: 768px) and (max-width: 1299px)' }
    ]);
});
